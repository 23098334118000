<template>
	<v-card flat>
		<v-card-title>
			Sıkça Sorulan Sorular<v-spacer></v-spacer>
			<v-btn depressed class="text-capitalize" @click="add">
				<v-icon left x-small v-text="'fas fa-plus'"></v-icon>
				yeni
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-list dense>
				<v-list-item dense class="px-0" v-for="(faq, index) in formData" :key="`faqs-${index}`">
					<v-list-item-content class="d-flex flex-row">
						<v-row>
							<v-col cols="12" md="6">
								<v-text-field
									label="Soru"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									v-model="faq.question"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-text-field
									label="Cevap"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									v-model="faq.answer"
								></v-text-field>
							</v-col>
						</v-row>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn outlined color="red darken-4" depressed class="text-capitalize" @click="remove(index)">
							<v-icon left x-small v-text="'fas fa-trash'"></v-icon>
							sil
						</v-btn>
					</v-list-item-action>
				</v-list-item>
			</v-list>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'CarReservationFaqs',
	props: {
		formData: {
			type: [],
			required: true,
			default: () => [],
		},
	},
	computed: {
		filteredData() {
			return this.formData;
		},
	},
	methods: {
		add() {
			this.formData.push({
				id: 0,
				order: this.formData.length + 1,
				question: null,
				answer: null,
				isDeleted: false,
			});
		},
		remove(index) {
			this.formData.splice(index, 1);
		},
		updateFormData() {
			this.$emit('update:formData', this.formData);
		},
	},
	watch: {
		formData: {
			handler() {
				this.updateFormData();
			},
		},
	},
};
</script>

<style></style>
