<template>
	<v-card flat>
		<v-card-title>
			Süreçler<v-spacer></v-spacer>
			<v-btn depressed class="text-capitalize" @click="add">
				<v-icon left x-small>fas fa-plus</v-icon>
				yeni
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-list dense>
				<v-list-item dense class="px-0" v-for="(step, index) in filteredData" :key="`businessStep-${index}`">
					<v-list-item-content class="d-flex flex-row">
						<v-row align="center">
							<v-col cols="12" md="2">
								<div v-if="step.id !== 0 && !step.isBlob" class="d-flex justify-center align-center">
									<v-img
										v-if="step.icon.isBlob"
										contain
										width="100"
										height="60"
										:lazy-src="step.icon.data"
										:src="step.icon.data"
									></v-img>
									<v-img
										v-else
										contain
										width="1000"
										height="60"
										:lazy-src="step.icon.name"
										:src="step.icon.name"
									></v-img>
								</div>
								<div v-else class="d-flex flex-column justify-center align-center">
									<v-img
										class="mb-2"
										contain
										width="100"
										height="60"
										:lazy-src="step.icon.blob"
										:src="step.icon.blob"
										v-if="step.icon.blob"
									></v-img>
									<v-btn
										class="text-capitalize"
										color="grey lighten-3"
										depressed
										light
										@click="chooseStepFile(index)"
										small
									>
										resim seç
									</v-btn>
									<input
										type="file"
										:ref="`stepFile-${index}`"
										class="d-none"
										accept=".pdf, .png, .jpg, .jpeg, .gif, .bmp"
										@change="changeStepFile($event, index)"
									/>
								</div>
							</v-col>
							<v-col cols="12" md="5">
								<v-text-field
									label="Başlık"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									v-model="step.title"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="5">
								<v-text-field
									label="Alt Başlık"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									v-model="step.desc"
								></v-text-field>
							</v-col>
						</v-row>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn outlined color="red darken-4" depressed class="text-capitalize" @click="remove(step)">
							<v-icon left x-small>fas fa-trash</v-icon>
							sil
						</v-btn>
					</v-list-item-action>
				</v-list-item>
			</v-list>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'CarReservationSteps',
	props: {
		formData: {
			type: [],
			required: true,
			default: () => [],
		},
	},
	computed: {
		filteredData() {
			return this.formData.filter((x) => !x.isDeleted);
		},
	},
	methods: {
		chooseStepFile(index) {
			const input = this.$refs[`stepFile-${index}`][0];
			if (input) {
				input.click();
			}
		},
		getFile(file) {
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.onload = (e) => {
					const array = new Uint8Array(e.target.result);
					const byte = [];
					for (let i = 0; i < array.length; i += 1) {
						byte.push(array[i]);
					}
					resolve({ name: file.name, file: byte });
				};
				reader.readAsArrayBuffer(file);
			});
		},
		async changeStepFile(e, index) {
			const data = e.target.files[0];

			this.checkFileExtension([data]).then(async () => {
				if (data) {
					const response = await this.getFile(data);

					const step = this.formData.filter((x) => !x.isDeleted)[index];

					if (step) {
						step.icon = {
							data: response.file,
							name: response.name,
							type: data.type,
							blob: await this.readAsDataURL(data),
						};
					}
				}
			});
		},
		async readAsDataURL(file) {
			const fileReader = new FileReader();
			return new Promise((resolve) => {
				if (file.type.indexOf('image') > -1) {
					this.showThumbnail = true;
					fileReader.onload = () => {
						resolve(fileReader.result);
					};
				}
				fileReader.readAsDataURL(file);
			});
		},
		checkFileExtension(files) {
			return new Promise((resolve, reject) => {
				const allowedExtensions = /(\.png|\.jpg|\.jpeg)$/i;

				const hasIgnoredExt = Array.from(files).some((f) => !allowedExtensions.exec(f?.name));

				if (hasIgnoredExt) {
					reject();
					this.setMessageDialog({
						messageType: 'error',
						subTitle: 'Hatalı!',
						text: 'Geçersiz dosya türü!',
					});
				}
				resolve();
			});
		},
		add() {
			this.formData.push({
				id: 0,
				icon: {},
				order: this.formData.length + 1,
				title: null,
				desc: null,
				isDeleted: false,
				isBlob: true,
				blob: null,
			});
		},
		remove(step) {
			if (step.id === 0) {
				const index = this.formData.findIndex((x) => x.id === step.id);
				this.formData.splice(index, 1);
			} else {
				const removedStep = step;
				removedStep.isDeleted = true;
			}
		},
		updateFormData() {
			this.$emit('update:formData', this.formData);
		},
	},
	watch: {
		formData: {
			handler() {
				this.updateFormData();
			},
		},
	},
};
</script>

<style></style>
