<template>
	<div>
		<car-reservation-settings :form-data.sync="model.settings" />
		<div v-if="reservationIsActive">
			<car-reservation-steps :form-data.sync="model.steps" />
			<car-reservation-faqs :form-data.sync="model.faqs" />
		</div>
		<v-card-text>
			<v-btn class="text-capitalize" dark color="teal" @click="save">Değişiklikleri kaydet</v-btn>
		</v-card-text>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_SITE_SETTINGS, UPDATE_CAR_RESERVATION_SETTINGS } from '@/store/modules/company.module';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import CarReservationSettings from './components/settings/Index.vue';
import CarReservationSteps from './components/steps/Index.vue';
import CarReservationFaqs from './components/faqs/Index.vue';

export default {
	components: {
		CarReservationSettings,
		CarReservationSteps,
		CarReservationFaqs,
	},
	name: 'CarReservation',
	data() {
		return {
			model: {
				settings: {
					isActive: false,
					expireHour: null,
					optionPrice: null,
					optionRate: null,
				},
				steps: [],
				faqs: [],
			},
		};
	},
	computed: {
		...mapGetters({
			settings: 'settings',
		}),
		reservationIsActive() {
			return this.model.settings.isActive;
		},
	},
	mounted() {
		this.fetchSettings();
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchSettings: FETCH_SITE_SETTINGS,
			update: UPDATE_CAR_RESERVATION_SETTINGS,
		}),
		save() {
			this.update({ configs: this.model.settings, stepList: this.model.steps, faqList: this.model.faqs }).then(() => {
				this.setMessageDialog({
					messageType: 'info',
					subTitle: 'Başarılı!',
					text: 'Değişiklikler başarılı bir şekilde kaydedilmiştir.',
				});
			});
		},
	},
	watch: {
		'settings.company': {
			handler(value) {
				const company = this.$cloneDeep(value);
				this.model.settings = {
					isActive: company.cmpReservationIsActive,
					kvkkFile: { name: company.cmpReservationKvkkFile },
					expireHour: company.cmpReservationExpireHour,
					optionPrice: company.cmpReservationOptionPrice,
					optionRate: company.cmpReservationOptionRate,
				};
				this.model.steps = company.carReservationStep.map((bs) => ({
					id: bs.crsId,
					icon: {
						data: null,
						name: bs.fileName,
						type: null,
					},
					title: bs.crsTitle,
					desc: bs.crsDesc,
					isDeleted: false,
				}));

				this.model.faqs = company.carReservationFaq.map((f) => ({
					id: f.crfId,
					question: f.crfQuestion,
					answer: f.crfAnswer,
				}));
			},
		},
	},
};
</script>

<style></style>
