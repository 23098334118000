<template>
	<v-card flat>
		<v-card-title>Araç Opsiyonlama Ayarları</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="2">
					<v-checkbox color="teal" hide-details="auto" label="Opsiyonlama aktif" v-model="formData.isActive">
					</v-checkbox>
				</v-col>
			</v-row>
			<v-row v-if="isActive">
				<v-col cols="12" md="2">
					<v-file-input
						color="teal"
						hide-details="auto"
						prepend-icon=""
						accept=".pdf"
						label="KVKK aydınlatma metni"
						outlined
						dense
						v-model="kvkkFile"
						clearable
						@click:clear="clearFile"
						@change="changeKvkkFile"
					>
						<template v-slot:prepend-inner>
							<v-icon v-text="'fas fa-paperclip'" small class="mt-1 mr-1"></v-icon>
						</template>
					</v-file-input>
					<label v-if="formData.kvkkFile.name?.indexOf('http') > -1">
						<a target="_blank" :href="formData.kvkkFile.name">yüklenen dosyayı aç</a>
					</label>
				</v-col>
				<v-col cols="12" md="2">
					<v-text-field
						color="teal"
						hide-details="auto"
						label="Opsiyon süresi (saat)"
						outlined
						dense
						v-model.number="formData.expireHour"
					>
					</v-text-field>
				</v-col>
				<v-col cols="12" md="3">
					<div class="d-flex flex-row">
						<v-btn-toggle class="mr-1" dense v-model="optionType">
							<v-btn height="40" :value="1">
								<v-icon>fa-money-bill-1</v-icon>
							</v-btn>

							<v-btn height="40" :value="2">
								<v-icon>fa-percent</v-icon>
							</v-btn>
						</v-btn-toggle>
						<v-text-field
							color="teal"
							hide-details="auto"
							label="Opsiyon bedeli"
							outlined
							dense
							v-if="optionType === 1"
							v-model.number="formData.optionPrice"
						>
						</v-text-field>
						<v-text-field
							color="teal"
							hide-details="auto"
							label="Opsiyon oranı (%)"
							outlined
							dense
							v-else
							v-model.number="formData.optionRate"
						>
						</v-text-field>
					</div>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'CarReservationSettings',
	props: {
		formData: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			optionType: null,
			tempDbKvkkFile: null,
			kvkkFile: null,
		};
	},
	computed: {
		isActive() {
			return this.formData.isActive;
		},
	},
	mounted() {
		// eslint-disable-next-line no-nested-ternary
		this.optionType = this.formData.optionRate !== null ? 2 : this.formData.optionPrice !== null ? 1 : 1;
	},
	methods: {
		clearFile() {
			this.formData.kvkkFile = this.tempDbKvkkFile;
		},
		checkFileExtension(files) {
			return new Promise((resolve, reject) => {
				const allowedExtensions = /(\.pdf)$/i;

				const hasIgnoredExt = Array.from(files).some((f) => !allowedExtensions.exec(f?.name));

				if (hasIgnoredExt) {
					reject();
					this.setMessageDialog({
						messageType: 'error',
						subTitle: 'Hatalı!',
						text: 'Geçersiz dosya türü!',
					});
				}
				resolve();
			});
		},
		getFile(file) {
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.onload = (e) => {
					const array = new Uint8Array(e.target.result);
					const byte = [];
					for (let i = 0; i < array.length; i += 1) {
						byte.push(array[i]);
					}
					resolve({ name: file.name, file: byte });
				};
				reader.readAsArrayBuffer(file);
			});
		},
		async changeKvkkFile() {
			const data = this.kvkkFile;
			this.tempDbKvkkFile = this.formData.kvkkFile;
			this.checkFileExtension([data]).then(async () => {
				if (data) {
					const response = await this.getFile(data);

					this.formData.kvkkFile = {
						data: response.file,
						name: response.name,
						type: data.type,
					};
				}
			});
		},
		updateFormData() {
			if (this.optionType === 1) this.formData.optionRate = null;
			if (this.optionType === 2) this.formData.optionPrice = null;

			if (!this.isActive) {
				this.formData.expireHour = null;
				this.formData.optionRate = null;
				this.formData.optionPrice = null;
			}

			this.$emit('update:formData', this.formData);
		},
	},
	watch: {
		formData: {
			handler() {
				// eslint-disable-next-line no-nested-ternary
				this.optionType = this.formData.optionRate !== null ? 2 : this.formData.optionPrice !== null ? 1 : 1;
				this.updateFormData();
			},
		},
	},
};
</script>

<style></style>
